import React, { useState } from "react";
import ReactPlayer from "react-player";
import { motion } from "framer-motion";

import { wrapper } from "./bannerVideo.module.css";

export const BannerVideo = ({ video, className }) => {
    const [isPlaying, setIsPlaying] = useState(false);
    return (
        <motion.div
            className={`${wrapper} ${className}`}
            style={{ opacity: isPlaying ? 1 : 0 }}
        >
            <ReactPlayer
                url={video}
                playing
                muted={true}
                loop={true}
                playsinline={true}
                onPlay={() => setIsPlaying(true)}
                // width={1920}
                // height={780}
            />
        </motion.div>
    );
};
